<template>
  <div class="page-body">
    <title-bar title="施工登记">
      <el-button type="primary" @click="save()" icon="fa fa-save" :loading="loading">
        保存
      </el-button>
      <el-button icon="fa fa-angle-left" @click="$router.back()">
        取消
      </el-button>
    </title-bar>
    <el-form ref="editForm" :model="bill" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-divider content-position="left">工单信息</el-divider>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工单编号" prop="requirementId">
            <el-autocomplete
                class="inline-input"
                v-model="bill.requirementId"
                clearable
                :fetch-suggestions="getRequirements"
                placeholder="请输入表号后6位"
                :trigger-on-focus="false"
                default-first-option
                style="width:100%"
                @select="requirementChange"
            >
              <template v-slot="{item}">
                {{item.requirementId}}-{{item.userName}}
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工单流程" prop="flowName">
            <code-select v-model="bill.flowName" code-type="flowName"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="客户编码" prop="userCode">
            <el-input v-model.trim="bill.userCode" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="客户名称" prop="userName">
            <el-input v-model.trim="bill.userName" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="客户电话号码" prop="userPhoneNum">
            <el-input v-model.trim="bill.userPhoneNum" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="地址" prop="address">
            <el-input v-model.trim="bill.address" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-divider content-position="left">施工信息</el-divider>
        </el-col>
        <el-col :span="6">
          <el-form-item label="网格信息" prop="team">
            <code-select v-model="bill.grid" code-type="grid"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上门时间" prop="workDate">
            <el-date-picker v-model="bill.workDate" value-format="yyyy-MM-dd" type="date"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="施工班组" prop="team">
            <code-select v-model="bill.team" code-type="team"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上门师傅" prop="worker">
            <code-select v-model="bill.worker" code-type="worker"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工程分类" prop="workType">
            <code-select v-model="bill.workType" code-type="workType"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="户改费" prop="hgfMoney">
            <el-input-number v-model.number="bill.hgfMoney" :min="0" :controls="false" placeholder="请输入费用"/>
            元
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="金属软管" prop="softPipeMoney">
            <code-select v-model="bill.softPipeFeeType" code-type="feeType" v-slot:prepend
                         style="width: 160px"></code-select>
            <el-input-number v-model.number="bill.softPipeMoney" :min="0" :controls="false" placeholder="请输入费用"/>
            元
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="自闭阀" prop="valveMoney">
            <code-select v-model="bill.valveFeeType" code-type="feeType" style="width: 160px"></code-select>
            <el-input-number v-model.number="bill.valveMoney" :min="0" :controls="false" placeholder="请输入费用"/>
            元
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="移交日期" prop="submitDate">
            <el-date-picker v-model="bill.submitDate" value-format="yyyy-MM-dd" type="date"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="bill.remark" clearable :autosize="{minRows:2, maxRows: 5}" :maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row :gutter="10">
      <el-col :span="12">
        <el-card>
          <div slot="header" class="materials-header">
            <span>燃气表具</span>
            <el-button type="primary" size="small" @click="addMeter">添加</el-button>
          </div>
          <el-table stripe :data="bill.meters" :border="true" v-loading="loading">
            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
            <el-table-column prop="meterNo" label="燃气表号" min-width="200" v-slot="{row}">
              <el-autocomplete
                  class="inline-input"
                  v-model="row.meterNo"
                  clearable
                  :fetch-suggestions="getUnusedMeters"
                  placeholder="请输入表号后6位"
                  :trigger-on-focus="false"
                  default-first-option
                  style="width:100%"
                  @select="(item)=>{row.meterNo = item.meterNo}"
              >
                <template v-slot="{item}">
                  {{item.meterNo}}-{{item.meterTypeName}}
                </template>
              </el-autocomplete>
            </el-table-column>
            <el-table-column prop="hangup" label="是否挂表" min-width="200" align="center" v-slot="{row}">
              <el-switch v-model="row.hangup" :active-value="true" :inactive-value="false"></el-switch>
            </el-table-column>
            <el-table-column prop="edit" label="删除" align="center" width="100" v-slot="{row,$index}">
              <el-button type="text" @click="delMeter($index)">删除</el-button>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="materials-header">
            <span>耗材清单</span>
            <el-button type="primary" size="small" @click="addMaterial">添加</el-button>
          </div>
          <el-table stripe :data="bill.materials" :border="true" v-loading="loading">
            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
            <el-table-column prop="materialCode" label="耗材" min-width="200" v-slot="{row}">
              <code-select v-model="row.materialCode" code-type="materials"></code-select>
            </el-table-column>
            <el-table-column prop="cnt" label="数量" min-width="200" align="center" v-slot="{row}">
              <el-input-number v-model="row.cnt" :controls="false" :min="0" placeholder="请输入数量"></el-input-number>
            </el-table-column>
            <el-table-column prop="edit" label="删除" align="center" width="100" v-slot="{row,$index}">
              <el-button type="text" @click="delMaterial($index)">删除</el-button>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>


    <div class="button-container">
      <el-button type="primary" @click="save()" icon="fa fa-save" :loading="loading">
        保存
      </el-button>
      <el-button icon="fa fa-angle-left" @click="$router.back()">
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar.vue";
import TablePagination from "@/components/base/TablePagination.vue";
import CodeSelect from "@/components/base/CodeSelect.vue";
export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      id: null,
      total: 0,
      bill: {
        requirementId: null,
        flowName: null,
        userCode: null,
        userName: null,
        userPhoneNum: null,
        address: null,
        workDate: null,
        hgfMoney: 0,
        softPipeMoney: 0,
        valveMoney: 0,
        meters: [],
        materials: []
      },
      rules: {
        requirementId: [{required: true, message: "工单编号不能为空", trigger: "change"}],
        workDate: [{required: true, message: "上门时间不能为空", trigger: "change"}],
        // team: [{required: true, message: "班组不能为空", trigger: "change"}],
        workType: [{required: true, message: "工程量不能为空", trigger: "change"}],
        // worker: [{required: true, message: "上门师傅不能为空", trigger: "blur"}],
        hgfMoney: [{required: true, message: "户改费为空时请填0", trigger: "blur"}],
        softPipeMoney: [{required: true, message: "软管费用为空时请填0", trigger: "blur"},{
          validator: (rule, value, cb)=>{
            if(!isNaN(value) && value > 0 && !this.bill.softPipeFeeType) {
              cb(new Error("费用不为0时，请选择费用类型"));
            } else {
              cb()
            }
          },
          trigger: "blur"
        }],
        valveMoney: [{required: true, message: "自闭阀费用为空时请填0", trigger: "blur"},{
          validator: (rule, value, cb)=>{
            if(!isNaN(value) && value > 0 && !this.bill.valveFeeType) {
              cb(new Error("费用不为0时，请选择费用类型"));
            } else {
              cb()
            }
          },
          trigger: "blur"
        }],
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    if(this.id) {
      this.getBill();
    } else {
      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      this.bill.workDate = this.formatDate(yesterday);
    }
  },
  methods: {
    getBill() {
      this.loading = true;
      this.postForm("/gas-work/getById", {
        id: this.id
      },true).then((dr)=>{
        this.bill = dr.bean || {}
        this.bill.hgfMoney = this.bill.hgfMoney || 0;
        this.bill.softPipeMoney = this.bill.softPipeMoney || 0;
        this.bill.valveMoney = this.bill.valveMoney || 0;
      }).finally(()=>{
        this.loading = false;
      })
    },
    save(){
      this.$refs["editForm"].validate(ok => {
        if (!ok) {
          return;
        }
        //检查表具
        for (let meter of this.bill.meters) {
          if(!meter.meterNo) {
            this.$message.warning("请填写表号或删除此行");
            return;
          }
        }
        //检查耗材
        for (let mat of this.bill.materials) {
          if(!mat.materialCode) {
            this.$message.warning("请填写耗材或删除此行");
            return;
          }
          if(isNaN(mat.cnt) || mat.cnt <= 0) {
            this.$message.warning("请填写正确的耗材数量");
            return;
          }
        }

        this.loading = true;
        this.postJson("/gas-work/save", this.bill,true).then((dr)=>{
          this.$router.back();
        }).finally(()=>{
          this.loading = false;
        });
      });
    },
    /**
     * 获取未使用的表号
     */
    getUnusedMeters(query, cb){
      if (query.length >= 3) {
        this.postForm("/gas-meter/list", {
          meterNo: query,
          hangup: false,
          page: 1,
          limit: 30
        }).then(dr => {
          cb(dr.rows);
        });
      } else {
        cb([])
      }
    },
    getRequirements(query, cb) {
      if (query.length >= 3) {
        this.loading = true
        this.postForm("/requirement/list", {
          requirementId: query,
          finished: false,
          page: 1,
          limit: 30
        }).then(dr => {
          cb(dr.rows);
        }).finally(()=>{
          this.loading = false;
        });
      } else {
        cb([]);
      }
    },
    requirementChange(req){
      this.bill.requirementId = req.requirementId;
      this.bill.flowName = req.flowName;
      this.bill.userCode = req.userCode;
      this.bill.userName = req.userName;
      this.bill.userPhoneNum = req.userPhoneNum;
      this.bill.address = req.address;
    },
    addMaterial(){
      this.bill.materials.push({});
    },
    delMaterial(index) {
      this.bill.materials.splice(index, 1)
    },
    addMeter(){
      this.bill.meters.push({});
    },
    delMeter(index) {
      this.bill.meters.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
